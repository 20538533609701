export const timeCloseAlert = 3000;

export const USER_ROLES = ['COPYWRITER', 'HR', 'COPYWRITER + HR', 'ADMIN'];

export const CATEGORIES = [
    {
        name: 'TRENDS',
        quantity: null
    },
    {
        name: 'DEVELOPMENT',
        quantity: null
    },
    {
        name: 'WEB TECHNOLOGIES',
        quantity: null
    },
    {
        name: 'DESIGN',
        quantity: null
    },
    {
        name: 'MOBILE',
        quantity: null
    }]



//DEV
/* export const API_URL = 'http://5.183.8.125:3700';
export const BLOG_URL = 'http://5.183.8.125:3900';
export const RECAPTCHA_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';   */



//PROD
export const API_URL = 'https://nitrix.io';
export const BLOG_URL = 'https://blog.nitrix.io';
export const RECAPTCHA_KEY = '6LdwaOMmAAAAANZ5ZTfoG0b11TFg4PRLjTGSg4sW'; 




//LOCAL